import { useQuery, useMutation, useQueryClient, UseMutateAsyncFunction } from "@tanstack/react-query";
import { QuoteAPI } from "../utils/QuoteAPI";
import { PetUnderwriterType } from "spot-types/entities/PetQuote";
import { PetQuote } from "spot-types/entities/PetQuote";

interface UseScratchPadReturnType {
    scratchPadValue: Record<string, any> | undefined;
    scratchPadIsLoading: boolean;
    scratchPadError: unknown;
    setScratchPadValue: (value: any) => void;
    setScratchPadValueAsync: UseMutateAsyncFunction<PetQuote<Record<string, any>> | undefined, Error, any, unknown>;
    setScratchPadValueIsLoading: boolean;
    deleteScratchPadValue: () => void;
    deleteScratchPadValueIsLoading: boolean;
}

export const useScratchPad = ({ quoteId, key, underwriter }: { key: string; quoteId: string | undefined; underwriter: PetUnderwriterType }): UseScratchPadReturnType => {
    const quoteApi = new QuoteAPI(underwriter);
    const queryClient = useQueryClient();

    // Query for getting the scratch pad value
    const scratchPadQuery = useQuery({
        retry: false,
        queryKey: ["scratchPad", quoteId, key],
        queryFn: () => quoteApi.getScratchPadValue(quoteId!, key),
        enabled: !!quoteId // Only run query if quoteId is provided
    });

    // Mutation for setting the scratch pad value
    const setScratchPadValue = useMutation({
        retry: 1,
        mutationKey: ["setScratchPadValue", quoteId, key],
        mutationFn: (value: any) => quoteApi.setScratchPadValue(quoteId!, key, value), // Updated the typing here
        onSuccess: () => {
            // Invalidate and refetch scratchPadQuery to get updated value
            if (!!quoteId) {
                queryClient.invalidateQueries({ queryKey: ["quote"] });
            }
        }
    });

    // Mutation for deleting the scratch pad value
    const deleteScratchPadValue = useMutation({
        mutationKey: ["deleteScratchPadValue", quoteId, key],
        mutationFn: () => quoteApi.deleteScratchPadValue(quoteId!, key),
        onSuccess: () => {
            // Invalidate and refetch scratchPadQuery to reflect the deletion
            queryClient.invalidateQueries({ queryKey: ["quote"] });
        }
    });

    return {
        // Exposing query data, error, and isLoading state for the scratchPadQuery
        scratchPadValue: scratchPadQuery.data,
        scratchPadIsLoading: scratchPadQuery.isLoading,
        scratchPadError: scratchPadQuery.error,
        // Exposing mutate functions and mutation status for setting and deleting scratch pad value
        setScratchPadValue: setScratchPadValue.mutate,
        setScratchPadValueAsync: setScratchPadValue.mutateAsync,
        setScratchPadValueIsLoading: setScratchPadValue.isPending,
        deleteScratchPadValue: deleteScratchPadValue.mutate,
        deleteScratchPadValueIsLoading: deleteScratchPadValue.isPending
    };
};
