import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCat, faCirclePlus, faDog } from "@fortawesome/pro-solid-svg-icons";
import { Species } from "spot-types/entities/Species";
import { Option } from "@/shared/types/Quote.interface";
import { PartialUsPetPolicies } from "@/quote-ptz-us/src/schema/PtzUsQuote";
import { PartialCaPetPolicies } from "@/quote-ptz-ca/src/schema/PtzCaQuote";
import { useAppLayerContext } from "@/shared/contexts/AppLayer";
import { UIUtils } from "@/shared/utils/UIUtils";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger, DropdownMenuSeparator } from "@/shared/components/ui/dropdown-menu";
import { Button, ButtonProps } from "@/shared/components/ui/Button";
import { Badge } from "@/shared/components/ui/Badge";
import PetModal, { PetModalConfig } from "@/shared/components/PetModal";
import { useCoverageEditorContext } from "../../CoverageEditor";
import Strings from "@/shared/utils/Strings.constants";
import LoaderWithText from "../../LoaderWithText";
import { CoverageUtils } from "@/shared/utils/CoverageUtils";
import { cn } from "@/shared/utils";

export interface PetDropdownProps {
    policyPets: Option[];
    handleActivePetChange: (value: string) => void;
    petModalConfig: PetModalConfig;
    triggerButton: React.ReactElement<ButtonProps>;
    containerRef?: React.RefObject<HTMLDivElement>;
    showFooter?: boolean;
    showEditButton?: boolean;
    isMultiPetLinked?: boolean;
    align?: "center" | "end" | "start";
    variant?: "default" | "secondary";
}

const PetDropdown: React.FC<PetDropdownProps> = ({
    policyPets,
    handleActivePetChange,
    petModalConfig,
    triggerButton,
    containerRef,
    showFooter,
    showEditButton,
    isMultiPetLinked,
    align = "start"
}) => {
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [editPetInitialValues, setEditPetInitialValues] = useState<PartialUsPetPolicies | PartialCaPetPolicies | null>(null);
    const [isEditModalOpen, setIsEditModalOpen] = useState(Array(policyPets.length).fill(false));

    const petInfoRefs = useRef<(HTMLDivElement | null)[]>([]);
    const petEditRefs = useRef<(HTMLButtonElement | null)[]>([]);

    const { appState } = useAppLayerContext();
    const { quoteQuery, isQuoteUpdating, calculatedPriceData } = appState;
    const quote = quoteQuery?.data;
    const policies = quote?.policies;

    const editorConfig = useCoverageEditorContext();
    const { getModalInitialValues, includeTransactionFee, feeType } = editorConfig;
    const defaultCoverageSettings = policies?.[0]?.coverageSettings;

    const handleOpenAddModal = () => setIsAddModalOpen(true);
    const handleCloseAddModal = () => setIsAddModalOpen(false);

    const handleOpenEditModal = (index: number) => {
        setIsEditModalOpen(prevState => prevState.map((val, i) => (i === index ? true : val)));
    };

    const handleCloseEditModal = (index: number) => {
        setIsEditModalOpen(prevState => prevState.map((val, i) => (i === index ? false : val)));
        setEditPetInitialValues(null);
    };

    const handleAddPetKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === "Enter" || e.key === " ") {
            e.preventDefault();
            handleOpenAddModal();
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>, index: number) => {
        if (e.key === "ArrowRight" && showEditButton) {
            e.preventDefault();
            petEditRefs.current[index]?.focus();
        } else if (e.key === "ArrowLeft" && showEditButton) {
            e.preventDefault();
            petInfoRefs.current[index]?.focus();
        }
    };

    useEffect(() => {
        if (editPetInitialValues) {
            setIsEditModalOpen(prevState => {
                const newState = [...prevState];
                newState[policyPets.findIndex(pet => pet.value === editPetInitialValues.policies?.[0]?.id)] = true;
                return newState;
            });
        }
    }, [editPetInitialValues, policyPets]);

    const petDropdownOptions = policyPets.map((pet, index) => {
        const matchingPolicy = policies?.find(policy => policy.id === pet.value);
        if (!matchingPolicy?.id) return null;

        const { roundedPrice, discountsAmount } = CoverageUtils.getPriceInfoData({ value: quote, includeTransactionFee, feeType, petIdToShow: matchingPolicy.id });
        const calculatedPetData = calculatedPriceData?.singlePetPriceData[matchingPolicy.id];
        const formattedTotalBeforeDiscount = calculatedPetData?.preDiscountPrice ?? UIUtils.formatNumber(roundedPrice + discountsAmount, 2);
        const formattedTotalPrice = calculatedPetData?.finalPrice ?? UIUtils.formatNumber(roundedPrice, 2);
        const billingFreqText = quote?.billingInfo?.frequency === "monthly" ? "mo" : "yr";
        const speciesIcon = matchingPolicy?.species === Species.Dog ? faDog : faCat;

        const editPetInitialValues: PartialUsPetPolicies | PartialCaPetPolicies = {
            policies: policies
                ?.filter(policy => policy.id === matchingPolicy?.id)
                .map(policy => ({
                    id: policy.id,
                    name: policy.name,
                    species: policy.species,
                    breedID: policy.breedID,
                    gender: policy.gender,
                    age: policy.age,
                    coverageSettings: policy.coverageSettings,
                    extra: policy.extra
                }))
        };

        const itemClass = {
            "focus:bg-background-transparent": isMultiPetLinked
        };

        return (
            <div key={pet.value} className="flex items-center" onKeyDown={e => handleKeyDown(e, index)}>
                <DropdownMenuItem
                    ref={el => (petInfoRefs.current[index] = el)}
                    key={`${pet.value}-info`}
                    onSelect={isMultiPetLinked ? undefined : () => handleActivePetChange(pet.value)} // If multipet, do nothing on click
                    className={cn("min-w-0 grow rounded-md !opacity-100", itemClass)}
                    data-testid={`pet-dropdown-item-${index}`}
                >
                    <div className="flex w-full items-center justify-between gap-2">
                        <div className="flex min-w-0 shrink items-center gap-2">
                            <FontAwesomeIcon icon={speciesIcon} className="size-5 shrink-0 text-content-primary" />
                            <span className="truncate text-sm font-medium">{pet.label}</span>
                        </div>

                        <div className="flex shrink-0 items-center gap-1 ">
                            {isQuoteUpdating && !calculatedPetData ? (
                                <LoaderWithText spinnerSize="xs" text={Strings.CALCULATING} className="gap-1 text-sm" />
                            ) : (
                                <>
                                    {!!discountsAmount && (
                                        <span className="whitespace-nowrap text-xs font-medium leading-5 text-content-secondary line-through">${formattedTotalBeforeDiscount}</span>
                                    )}
                                    <span className="whitespace-nowrap text-sm font-bold leading-5 text-content-primary">
                                        ${formattedTotalPrice}/{billingFreqText}
                                    </span>
                                </>
                            )}
                        </div>
                    </div>
                </DropdownMenuItem>
                {showEditButton && (
                    <PetModal
                        initialValues={editPetInitialValues}
                        triggerButton={
                            <DropdownMenuItem asChild onSelect={e => e.preventDefault()}>
                                <Button
                                    ref={el => (petEditRefs.current[index] = el)}
                                    variant="link"
                                    className="h-full w-auto whitespace-nowrap text-xs font-medium leading-5 text-content-secondary focus:rounded-none focus:shadow-none active:rounded-none active:shadow-none"
                                    onClick={e => {
                                        e.stopPropagation();
                                        setEditPetInitialValues(editPetInitialValues);
                                        handleOpenEditModal(index);
                                    }}
                                    onTouchStart={e => {
                                        e.stopPropagation();
                                        setEditPetInitialValues(editPetInitialValues);
                                        handleOpenEditModal(index);
                                    }}
                                    onKeyDown={e => {
                                        if (e.key === "Enter" || e.key === " ") {
                                            e.preventDefault();
                                            setEditPetInitialValues(editPetInitialValues);
                                            handleOpenEditModal(index);
                                        }
                                    }}
                                >
                                    {Strings.EDIT_PET}
                                </Button>
                            </DropdownMenuItem>
                        }
                        isOpen={isEditModalOpen[index]}
                        handleOpen={() => handleOpenEditModal(index)}
                        onClose={() => handleCloseEditModal(index)}
                        config={petModalConfig}
                        handleActivePetChange={handleActivePetChange}
                    />
                )}
            </div>
        );
    });

    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>{triggerButton}</DropdownMenuTrigger>
            <DropdownMenuContent align={align} container={containerRef} className="mx-4 w-[calc(100vw-2rem)] p-1 sm:mx-6 sm:w-[calc(100vw-3rem)] md:mx-2 md:w-[400px]">
                {petDropdownOptions}
                {showFooter && (
                    <>
                        <DropdownMenuSeparator />
                        <PetModal
                            initialValues={{ policies: [getModalInitialValues(defaultCoverageSettings, !!isMultiPetLinked)] }}
                            triggerButton={
                                <DropdownMenuItem asChild onSelect={e => e.preventDefault()}>
                                    <Button
                                        variant="ghost"
                                        className="h-auto border-t border-stroke-secondary p-0 focus:rounded-none focus:shadow-none active:rounded-none active:shadow-none"
                                        onClick={e => {
                                            handleOpenAddModal();
                                        }}
                                        onTouchStart={e => {
                                            handleOpenAddModal();
                                        }}
                                        onKeyDown={handleAddPetKeyDown}
                                    >
                                        <div className="flex w-full items-center justify-between p-2">
                                            <div className="flex items-center gap-2">
                                                <FontAwesomeIcon icon={faCirclePlus} className="size-5 text-content-primary" />
                                                <span className="text-sm">{Strings.ADD_PET}</span>
                                            </div>
                                            <div className="flex flex-col gap-1">
                                                <Badge variant="secondary" className="text-xs">
                                                    10% multi-pet discount
                                                </Badge>
                                            </div>
                                        </div>
                                    </Button>
                                </DropdownMenuItem>
                            }
                            isOpen={isAddModalOpen}
                            handleOpen={handleOpenAddModal}
                            onClose={handleCloseAddModal}
                            config={petModalConfig}
                            handleActivePetChange={handleActivePetChange}
                        />
                    </>
                )}
            </DropdownMenuContent>
        </DropdownMenu>
    );
};

export default PetDropdown;
