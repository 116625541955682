import React, { useState } from "react";
import { Button } from "./ui/Button";
import InfoIcon from "@/shared/media/icons/CircleInfoProRegular.svg";
import { ResponsiveModalSheet } from "./ResponsiveModalSheet";
import { cn } from "../utils";

const TakeoverProvision = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpenModal = () => setIsModalOpen(true);
    const handleCloseModal = () => setIsModalOpen(false);

    const handleTouchStart = (e: React.TouchEvent) => {
        handleOpenModal();
    };

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === "Enter" || e.key === " ") {
            e.preventDefault();
            handleOpenModal();
        }
    };

    const triggerButton = (
        <Button
            variant="ghost"
            size="sm"
            className="max-w-fit text-xs"
            startDecorator={<InfoIcon className="h-4" />}
            onClick={handleOpenModal}
            onTouchStart={handleTouchStart}
            onKeyDown={handleKeyDown}
        >
            Program Terms
        </Button>
    );

    const modalContent = (
        /* scroll-container-custom = app/globals.css for styling scrollbars */
        <div className="scroll-container-custom relative h-full overflow-y-scroll">
            <div className="flex flex-col gap-4 pb-10 text-base font-medium">
                <p>
                    If you are currently insured under an employer-offered plan with a different carrier, maintain continuous coverage to your Spot policy. If you enroll with Spot
                    Pet Insurance, conditions that are currently eligible  will not be subject to a new pre-existing condition exclusion and will not be subject to a waiting period
                    on the Spot Policy. You must submit documentation of the prior continuous coverage. Details will be provided following enrollment.
                </p>
                <p>
                    Accident & Illness plan options include the availability of the Pre-ex Takeover Endorsement Program. Accident Only plans do not include the endorsement.
                    Additional Annual Limit Options are available that do not include the endorsement. For more options, call{" "}
                    <a href="tel:18883432340" className="whitespace-nowrap hover:underline">
                        1-888-343-2340
                    </a>
                    .
                </p>
            </div>

            <div
                className="pointer-events-none fixed inset-x-0 bottom-20 h-10 sm:bottom-14 md:bottom-16"
                style={{ background: "linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%)" }}
            ></div>
        </div>
    );

    return (
        <div className="mt-4 flex justify-center">
            <div className="flex flex-col gap-1 rounded-md bg-background-brand-weakest p-4 md:max-w-[572px] md:items-center lg:max-w-[816px]">
                <h4 className="text-sm font-bold md:text-center">Have you had employer-offered pet insurance before? If so, good news:</h4>
                <p className="text-xs font-medium md:text-center">
                    Eligible conditions aren’t subject to a new pre-existing condition and there’s no waiting period. Accident Only plans aren’t eligible.
                </p>
                <div onKeyDown={e => e.stopPropagation()}>
                    <ResponsiveModalSheet
                        isOpen={isModalOpen}
                        onClose={handleCloseModal}
                        title={<h4 className="text-lg font-bold">How does the Pre-ex Takeover Endorsement Program Work?</h4>}
                        sheetSide="bottom"
                        triggerButton={React.cloneElement(triggerButton)}
                        showCloseButton={true}
                        styles={{
                            sheet: {
                                content: cn("flex flex-col max-h-[calc(100vh-80px)]"),
                                title: "flex justify-between items-center"
                            },
                            modal: {
                                title: "flex justify-between items-center",
                                content: "flex flex-col !max-w-[624px]",
                                closeButton: "hidden"
                            }
                        }}
                    >
                        {isModalOpen && modalContent}
                    </ResponsiveModalSheet>
                </div>
            </div>
        </div>
    );
};

export default TakeoverProvision;
