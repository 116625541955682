import React, { useState, useRef } from "react";
import { Policy } from "@/shared/types/Quote.interface";
import { faDog, faCat, faPencil, faPaw } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@/shared/components/ui/Button";
import { Card, CardHeader, CardContent } from "@/shared/components/ui/Card";
import PetModal, { PetModalConfig } from "../PetModal";
import { useAppLayerContext } from "@/shared/contexts/AppLayer";
import { PriceInfo } from "../PriceInfo";
import { CoverageUtils } from "@/shared/utils/CoverageUtils";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { cn } from "@/shared/utils";
import { Badge } from "../ui";
import { useCoverageEditorContext } from "../CoverageEditor";
import LoaderWithText from "../LoaderWithText";
import Strings from "@/shared/utils/Strings.constants";

interface PetCardProps {
    pet: Policy;
    isQuoteUpdating?: boolean;
    petModalConfig: PetModalConfig;
    quoteId: string;
    activePetId: string;
    policiesCount: number;
    handleActivePetChange: (value: string) => void;
    isMultiPetLinked?: boolean;
    isApplyAllHidden?: boolean;
    onSelect?: () => void;
}

const PetCard: React.FC<PetCardProps> = ({
    pet,
    isQuoteUpdating,
    petModalConfig,
    activePetId,
    policiesCount,
    handleActivePetChange,
    isMultiPetLinked,
    onSelect,
    isApplyAllHidden
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    // Statsig - reduced-height
    const { hasReducedHeight } = useAppLayerContext().appState;

    const quote = useAppLayerContext().appState.quoteQuery?.data;
    const calculatedPriceData = useAppLayerContext().appState.calculatedPriceData;
    const { includeTransactionFee, feeType } = useCoverageEditorContext();
    const priceInfoData = CoverageUtils.getPriceInfoData({ value: quote, includeTransactionFee, feeType, petIdToShow: !isMultiPetLinked ? pet.id : undefined });
    const isActivePetCard = !!activePetId && activePetId === pet.id;
    const isMultiPetActive = !isApplyAllHidden && isMultiPetLinked && policiesCount > 1;
    const touchStartTime = useRef<number | null>(null);
    const touchStartPosition = useRef<{ x: number; y: number } | null>(null);

    if (!isMultiPetLinked && !pet.id) return null;

    const handleEditPet = (e: React.MouseEvent | React.TouchEvent | React.KeyboardEvent) => {
        setIsModalOpen(true);
    };

    const handleTouchStart = (e: React.TouchEvent) => {
        const touch = e.touches[0];
        if (touch) {
            touchStartTime.current = Date.now();
            touchStartPosition.current = { x: touch.clientX, y: touch.clientY };
        }
    };

    const handleTouchEnd = (e: React.TouchEvent) => {
        const touch = e.changedTouches[0];
        if (touchStartTime.current && touchStartPosition.current && touch) {
            const touchEndTime = Date.now();
            const touchEndPosition = { x: touch.clientX, y: touch.clientY };

            const touchDuration = touchEndTime - touchStartTime.current;
            const touchDistance = Math.sqrt(Math.pow(touchEndPosition.x - touchStartPosition.current.x, 2) + Math.pow(touchEndPosition.y - touchStartPosition.current.y, 2));

            // If the touch was short or long but didn't move much, consider it a tap or long press
            if (touchDistance < 10 || (touchDuration >= 500 && touchDistance < 20)) {
                handleEditPet(e);
            }
        }
    };

    const handleKeyUp = (e: React.KeyboardEvent) => {
        if (e.key === "Enter" || e.key === " ") {
            handleEditPet(e);
        }
    };

    const handleCardClick = () => {
        if (isQuoteUpdating) return;
        handleActivePetChange(pet?.id ?? activePetId);
        if (!!onSelect) {
            onSelect();
        }
    };

    const handleCardKeyUp = (e: React.KeyboardEvent) => {
        if (e.key === "Enter" || e.key === " ") {
            handleCardClick();
        }
    };
    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const getIcon = (): IconProp => {
        if (isMultiPetLinked && policiesCount > 1) {
            return faPaw;
        }
        if (pet.species === "Cat") {
            return faCat;
        }
        return faDog;
    };

    // styles
    const transitionClass = "transition-opacity duration-200 ease-in-out";

    return (
        <div
            role="button"
            tabIndex={0}
            onClick={handleCardClick}
            onKeyUp={handleCardKeyUp}
            className={cn(
                "flex h-full w-full flex-col overflow-hidden !rounded-3xl border-0 bg-content-primary-invert text-left sm:w-[328px]",
                transitionClass,
                "focus:shadow-none focus:outline-none",
                !isMultiPetLinked && !isActivePetCard && "opacity-50 hover:opacity-100"
            )}
        >
            <Card className={cn("size-full overflow-hidden !rounded-3xl")}>
                <CardHeader className="flex shrink-0 flex-row items-center justify-between whitespace-nowrap border-0 bg-background-primary-invert p-3 text-content-primary-invert">
                    <div className="flex min-w-0 flex-1 items-center gap-2">
                        {hasReducedHeight ? (
                            <div className="flex size-8 items-center justify-center">
                                <FontAwesomeIcon icon={getIcon()} className="size-6 shrink-0" />
                            </div>
                        ) : (
                            <FontAwesomeIcon icon={getIcon()} className="size-8 shrink-0" />
                        )}
                        <span className="truncate font-bold" data-testid="policy-count">
                            {isMultiPetActive ? `${policiesCount} Pets` : pet.name}
                        </span>
                    </div>
                    <PetModal
                        initialValues={{ policies: isMultiPetActive ? quote?.policies : [pet] }}
                        triggerButton={
                            <Button
                                loading={isQuoteUpdating}
                                loadingSpinnerPosition="start"
                                onClick={handleEditPet}
                                onTouchStart={handleTouchStart}
                                onTouchEnd={handleTouchEnd}
                                onKeyUp={handleKeyUp}
                                variant="invert"
                                className={cn("ml-1 shrink-0 text-xs font-medium leading-5", hasReducedHeight ? "h-8 py-0" : "")}
                                startDecorator={<FontAwesomeIcon icon={faPencil} className="size-5" />}
                                data-testid={`${isMultiPetActive ? "edit-pets-btn" : "edit-pet-btn"}`}
                            >
                                {isMultiPetActive ? Strings.EDIT_PETS : Strings.EDIT_PET}
                            </Button>
                        }
                        isOpen={isModalOpen}
                        handleOpen={() => setIsModalOpen(true)}
                        onClose={handleCloseModal}
                        config={petModalConfig}
                        handleActivePetChange={handleActivePetChange}
                    />
                </CardHeader>
                <CardContent className="flex grow flex-col items-center justify-center bg-background-secondary p-3">
                    {isQuoteUpdating && !calculatedPriceData ? (
                        <LoaderWithText spinnerSize="sm" text={Strings.CALCULATING} className="gap-1 text-sm" />
                    ) : (
                        <div>
                            {isMultiPetActive ? (
                                <PriceInfo
                                    variant="all-pets-card"
                                    petId={activePetId}
                                    totalPrice={priceInfoData.roundedPrice}
                                    discountsAmount={priceInfoData.discountsAmount}
                                    policiesCount={policiesCount}
                                />
                            ) : (
                                <PriceInfo
                                    variant="single-pet-card"
                                    petId={pet.id}
                                    totalPrice={priceInfoData.roundedPrice}
                                    discountsAmount={priceInfoData.discountsAmount}
                                    policiesCount={policiesCount}
                                />
                            )}
                        </div>
                    )}
                </CardContent>
            </Card>
        </div>
    );
};

export const PlaceholderPetCard = ({ pet, bgColor }: { pet: Policy; bgColor: string }) => (
    <div className="flex size-full flex-col overflow-hidden !rounded-3xl border-0 text-left sm:w-[328px]">
        <div className={cn("flex size-full flex-col !rounded-3xl border border-stroke-primary", bgColor)}>
            <div className="flex shrink-0 flex-row items-center justify-between whitespace-nowrap border-0 bg-background-primary-invert p-3 text-content-primary-invert">
                <div className="flex min-w-0 flex-1 items-center gap-2">
                    <FontAwesomeIcon icon={pet.species === "Cat" ? faCat : faDog} className="size-8 shrink-0" />
                    <span className="truncate font-bold">{pet.name}</span>
                </div>
                <Button disabled variant="invert" className="ml-1 shrink-0 text-xs font-medium leading-5" startDecorator={<FontAwesomeIcon icon={faPencil} className="size-5" />}>
                    {Strings.EDIT_PET}
                </Button>
            </div>
            <div className="flex grow flex-col items-center justify-center p-3">
                <div className="text-center">
                    <div className="text-2xl font-bold">${pet.basePrice?.value}/mo</div>
                    {pet.discountAmount?.value !== undefined && pet.discountAmount.value > 0 && (
                        <div className="text-xs sm:place-self-end">
                            <Badge variant="secondary">Discount(s) Applied</Badge>
                        </div>
                    )}
                </div>
            </div>
        </div>
    </div>
);
export default PetCard;
