import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//Utils, types, context
import { useFormMethodsContext } from "@/shared/components/Form";
import { Quote } from "@/shared/types/Quote.interface";
import Strings from "@/shared/utils/Strings.constants";
import { cn } from "@/shared/utils";

//Components
import LoadingSpinner from "@/shared/components/LoadingSpinner";
import { Button } from "@/shared/components/ui";
import { PriceInfo } from "@/shared/components/PriceInfo";
import { CoverageUtils } from "@/shared/utils/CoverageUtils";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { useCoverageEditorContext } from "@/shared/components/CoverageEditor";

const PinnedCTA = ({ quote, exclusionsInView, scrollVariant, priceVariant }: { quote: Quote; exclusionsInView?: boolean; scrollVariant?: boolean; priceVariant?: boolean }) => {
    const { onFormContinue, isValidating } = useFormMethodsContext();
    const [isVisible, setIsVisible] = useState(false);

    const editorConfig = useCoverageEditorContext();
    const { roundedPrice, discountsAmount } = CoverageUtils.getPriceInfoData({
        value: quote,
        includeTransactionFee: editorConfig.includeTransactionFee,
        feeType: editorConfig.feeType
    });
    const petsCount = quote.policies?.length;

    const conditionalStyles = {
        "ps-5 sm:ps-6": priceVariant,
        "translate-y-0 opacity-100": isVisible,
        "translate-y-full opacity-0": !isVisible
    };

    const buttonContentStyles = {
        "hidden sm:flex": isValidating
    };

    useEffect(() => {
        if (scrollVariant) {
            // If exclusions become visible, set the CTA to visible and never revert.
            if (exclusionsInView) {
                setIsVisible(true);
            }
        } else {
            // Timer-based behavior for non-scroll variant.
            const timer = setTimeout(() => {
                setIsVisible(true);
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [scrollVariant, exclusionsInView]);

    return (
        <>
            <div
                className={cn(
                    conditionalStyles,
                    "fixed inset-x-0 bottom-0 z-50 mx-auto flex items-center gap-4 rounded-t-3xl bg-content-primary p-2 pb-5 transition-all duration-500 sm:px-6 sm:pb-5 sm:pt-4 lg:max-w-[50%]"
                )}
            >
                {priceVariant && petsCount && <PriceInfo variant="footer-cta" totalPrice={roundedPrice} policiesCount={petsCount} discountsAmount={discountsAmount} />}
                <Button
                    endDecorator={isValidating ? <LoadingSpinner size="sm" /> : <FontAwesomeIcon icon={faChevronRight} />}
                    onClick={async () => await onFormContinue()}
                    type="button"
                    className="mx-auto size-full rounded-full px-4 py-3 focus:rounded-full"
                >
                    <div className={cn(buttonContentStyles, "items-center gap-2")}>{Strings.PROCEED_TO_CHECKOUT}</div>
                </Button>
            </div>
        </>
    );
};

export default PinnedCTA;
