import { useEffect, useState } from "react";
import { CoverageSettings, Policy, Quote } from "@/shared/types/Quote.interface";
import { Button, Label } from "@/shared/components/ui";
import { Checkbox } from "@/shared/components/ui/checkbox";
import { useAppLayerContext } from "@/shared/contexts/AppLayer";
import Strings from "@/shared/utils/Strings.constants";
import { useFormParentContext } from "@/shared/contexts/FormParent";
import type { CheckedState } from "@radix-ui/react-checkbox";
import { useScratchPad } from "../hooks/useScratchPad";
import { ResponsiveModalSheet } from "./ResponsiveModalSheet";
import { cn } from "../utils";

interface MultipetToggleProps {
    policies: Policy[] | undefined;
    activePet: Policy | undefined;
    allPetsHaveSameCoverages: boolean;
    quoteId: string | undefined;
    fieldId?: string;
    updateCoverageSettings: (coverageSettings: CoverageSettings, isApplyToAllPetsChecked: boolean) => Promise<void>;
}

const MultipetToggle = (props: MultipetToggleProps) => {
    const { policies, activePet, allPetsHaveSameCoverages, quoteId, fieldId = "all-pets", updateCoverageSettings } = props;
    const { appState, updateAppState } = useAppLayerContext();
    const { isMultiPetLinked, isQuoteUpdating, underwriter } = appState;

    const [isChecked, setIsChecked] = useState(!!isMultiPetLinked);
    const [isConfirming, setIsConfirming] = useState(false);

    const quoteHasMultiplePets = !!policies && policies.length > 1;

    const { setValue } = useFormParentContext<Quote>();

    const { setScratchPadValue, setScratchPadValueIsLoading } = useScratchPad({ quoteId, key: "extra", underwriter: underwriter ?? "ptz-us" });

    const updateQuoteValues = async (isChecked: CheckedState) => {
        if (isChecked === "indeterminate") return;

        setIsChecked(isChecked);
        setValue("extra.isMultiPetLinked", isChecked);
        updateAppState({ isMultiPetLinked: isChecked });

        if (!isChecked) {
            setScratchPadValue({ quote: { isMultiPetLinked: !isMultiPetLinked } });
            return;
        }

        if (!!quoteId && !!activePet?.coverageSettings) {
            try {
                await updateCoverageSettings(activePet.coverageSettings, isChecked);
            } catch (error) {
                console.error(`Error updating coverage settings: ${error}`);
                setIsChecked(!isChecked);
                setValue("extra.isMultiPetLinked", !isChecked);
                updateAppState({ isMultiPetLinked: !isChecked });
            }
        }
    };

    const handleApplyToAllCancel = () => {
        setIsConfirming(false);
    };

    const handleApplyToAllConfirm = async () => {
        await updateQuoteValues(true);
        setIsConfirming(false);
    };

    const onCheckboxChange = async (value: CheckedState) => {
        if (value === "indeterminate") return;
        if (allPetsHaveSameCoverages) {
            await updateQuoteValues(value);
        } else {
            setIsConfirming(true);
        }
    };

    useEffect(() => {
        setIsChecked(!!isMultiPetLinked);
    }, [isMultiPetLinked]);

    const activePetName = activePet?.name ?? "";

    const triggerButton = (
        <>
            {quoteHasMultiplePets && (
                <div className="flex items-center space-x-2">
                    <Checkbox
                        id={fieldId}
                        aria-labelledby={`${fieldId}-label`}
                        checked={isChecked}
                        onCheckedChange={async value => await onCheckboxChange(value)}
                        data-testid={fieldId}
                        disabled={isQuoteUpdating || setScratchPadValueIsLoading}
                    />
                    <Label id={`${fieldId}-label`} htmlFor={fieldId} className="mb-0 bg-background-transparent text-xs text-content-secondary" allowLabelClick>
                        {Strings.MULTIPET_LINKED_LABEL}
                    </Label>
                </div>
            )}
        </>
    );

    return (
        <div onKeyDown={e => e.stopPropagation()}>
            <ResponsiveModalSheet
                isOpen={isConfirming}
                onClose={handleApplyToAllCancel}
                sheetSide="top"
                showCloseButton={!isConfirming}
                triggerButton={triggerButton}
                styles={{
                    sheet: {
                        content: cn("flex flex-col", isConfirming && "pb-16"),
                        title: "flex justify-between items-center"
                    },
                    modal: {
                        title: "flex justify-between items-center",
                        content: "flex flex-col !max-w-[360px]",
                        closeButton: "hidden"
                    }
                }}
            >
                {isConfirming && !!activePet?.name && (
                    <div data-testid="apply-all-modal" className="flex size-full flex-col items-center justify-center gap-8">
                        <h2 lang="en" className="w-full text-balance text-center text-lg font-bold text-content-primary">
                            <span className="whitespace-normal">Are you sure you want to apply </span>
                            <span className="whitespace-nowrap">
                                <span className="inline-block max-w-[200px] truncate align-bottom">{activePetName}</span>
                                <span>&apos;s</span>
                            </span>
                            <span className="whitespace-normal"> plan to all pets?</span>
                        </h2>
                        <div className="flex w-full gap-4">
                            <Button data-testid="apply-all-cancel" autoFocus={true} variant="ghost" onClick={handleApplyToAllCancel} className="flex-1" disabled={isQuoteUpdating}>
                                {Strings.CONFIRMATION_CANCEL}
                            </Button>
                            <Button
                                data-testid="apply-all-confirm"
                                variant="default"
                                onClick={async () => await handleApplyToAllConfirm()}
                                className="flex-1"
                                disabled={isQuoteUpdating}
                                loading={isQuoteUpdating}
                            >
                                {isQuoteUpdating ? `Applying` : `Yes`}
                            </Button>
                        </div>
                    </div>
                )}
            </ResponsiveModalSheet>
        </div>
    );
};

export default MultipetToggle;
