import React, { useMemo, useRef, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { PetInfoEditor } from "./PetInfoEditor";
import { FormProvider, useForm } from "react-hook-form";
import { useBreeds } from "../hooks/useBreeds";
import { useAppLayerContext } from "@/shared/contexts/AppLayer";
import { UsPetPoliciesSchema, UsPetPolicies, PartialUsPetPolicies } from "@/quote-ptz-us/src/schema/PtzUsQuote";
import { CaPetPoliciesSchema, CaPetPolicies, PartialCaPetPolicies } from "@/quote-ptz-ca/src/schema/PtzCaQuote";
import { useFormParentContext } from "../contexts/FormParent";
import { ResponsiveModalSheet } from "./ResponsiveModalSheet";
import { PlusCircle } from "lucide-react";
import { Badge, Button, ButtonProps } from "./ui";
import { Option, Policy, Quote } from "../types/Quote.interface";
import { cn } from "../utils";
import { FormField } from "./FormField";
import DynamicOptionsField from "./DynamicOptionsField";
import Strings from "../utils/Strings.constants";
import { PublicConfig } from "../PublicConfig";
import { PRIORITY_CODE_DEPRECATED } from "../types/SpotAPI";
import ErrorUtils from "../utils/ErrorUtils";

export type PetModalConfig = {
    resolverSchema: typeof UsPetPoliciesSchema | typeof CaPetPoliciesSchema;
    topBreeds: string[];
    showMicrochip: boolean;
    formStyles: {
        wrapper: string;
        fields: Record<string, string>;
    };
    showBirthDate?: boolean;
    showAge?: boolean;
    ageField?: {
        maxAgeCat?: number;
        maxAgeDog?: number;
        maxAgeError?: string;
        constrainSelectWidthToTrigger?: boolean;
        label?: string;
        placeholder?: string;
    };
    breedField?: {
        label?: string;
        placeholder?: string;
    };
    nameField?: {
        label?: string;
    };
};

type PetModalProps = {
    initialValues: PartialUsPetPolicies | PartialCaPetPolicies;
    triggerButton: React.ReactElement<ButtonProps>;
    isOpen: boolean;
    handleOpen: () => void;
    onClose: () => void;
    config: PetModalConfig;
    handleActivePetChange: (value: string) => void;
};

const AddAPetTitle = () => (
    <>
        <span className="text-lg font-bold text-content-primary">{Strings.ADD_PET}</span>
        <Badge variant="secondary">{Strings.MULTIPET_DISCOUNT_ADDITIONAL_PETS}</Badge>
    </>
);

const PetModalForm = ({
    initialValues,
    onClose,
    config,
    handleActivePetChange,
    isEditMode,
    showRemoveConfirmation,
    setShowRemoveConfirmation
}: Pick<PetModalProps, "initialValues" | "onClose" | "config" | "handleActivePetChange"> & {
    isEditMode?: boolean;
    showRemoveConfirmation: boolean;
    setShowRemoveConfirmation: React.Dispatch<React.SetStateAction<boolean>>;
    isMultiPetLinked?: boolean;
}) => {
    const petOptions: Option[] = initialValues?.policies?.map(pet => ({ label: pet.name ?? "", value: pet.id ?? "" })) ?? [];
    const firstPet = initialValues?.policies?.[0];

    const [activePet, setActivePet] = useState<Policy | undefined>(firstPet);
    const activePetIndex: number = useMemo(() => (activePet ? (initialValues?.policies?.findIndex(pet => pet.id === activePet.id) ?? 0) : 0), [activePet, initialValues?.policies]);

    const {
        appState: { underwriter, updateQuote, isQuoteUpdating, mockAPIError },
        updateAppState
    } = useAppLayerContext();
    const { breeds } = useBreeds(underwriter);
    const { getValues, watch: watchParent, reset: resetParentForm } = useFormParentContext<Quote>();
    const totalPolicies = watchParent("policies") ?? [];
    const cancelRef = useRef<HTMLButtonElement>(null);
    const form = useForm<UsPetPolicies | CaPetPolicies>({
        resolver: zodResolver(config.resolverSchema),
        defaultValues: initialValues
    });

    const { watch, handleSubmit, reset } = form;
    const policies = watch("policies");

    const updateActivePet = (petId: string) => {
        const pet = initialValues?.policies?.find(pet => pet.id === petId);
        setActivePet(pet);
    };

    const handleClose = () => {
        onClose();
        reset(initialValues);
    };

    const onSubmit = async (data: UsPetPolicies | CaPetPolicies) => {
        try {
            const currentValues = getValues();
            const updatedPolicies = currentValues.policies ? [...currentValues.policies] : [];

            data.policies.forEach(submittedPolicy => {
                const existingPolicyIndex = updatedPolicies.findIndex(currentPolicy => currentPolicy.id === submittedPolicy.id);

                if (existingPolicyIndex > -1) {
                    updatedPolicies[existingPolicyIndex] = { ...updatedPolicies[existingPolicyIndex], ...submittedPolicy };
                } else {
                    updatedPolicies.push(submittedPolicy);
                }
            });

            const updatedData = {
                ...currentValues,
                policies: updatedPolicies
            };

            if (!!updateQuote) {
                const onUpdateQuote = async () => {
                    const updateType = isEditMode ? "UPDATE" : "PET_ADDED";
                    try {
                        return await updateQuote.mutateAsync({ quote: updatedData, updateType });
                    } catch (error) {
                        const hasPcodeError = ErrorUtils.hasSpotError(error, PRIORITY_CODE_DEPRECATED);
                        if (hasPcodeError) {
                            let retryData = { ...updatedData, affiliateCode: "SPOT", discountCode: "SPOT" };
                            if (PublicConfig.ENVIRONMENT === "development" && mockAPIError) {
                                // retry with blank sim param to clear the error
                                retryData = { ...retryData, extra: { ...retryData.extra, sim: "" } };
                            }
                            const retryQuote = await updateQuote?.mutateAsync({ quote: retryData, updateType });
                            return retryQuote;
                        }
                    }
                };

                const updatedQuote = await onUpdateQuote();

                if (!!updatedQuote && !!updatedQuote.policies?.length) {
                    resetParentForm(updatedQuote);
                    const newPet = updatedQuote.policies.find(updatedPet => !currentValues.policies?.some(currentPet => currentPet.id === updatedPet.id));
                    if (newPet?.id) {
                        // Set the newly added pet as the active pet
                        setActivePet(newPet);
                        handleActivePetChange(newPet.id);
                    }
                }
            } else {
                throw new Error("updateQuote is not defined");
            }

            reset(initialValues);
            onClose();
        } catch (error) {
            console.error("Failed to update policy:", error);
        }
    };

    const handleRemovePet = () => {
        setShowRemoveConfirmation(true);
    };

    const handleKeyUp = (event: React.KeyboardEvent<HTMLButtonElement>, type: "save" | "remove" | "close") => {
        if (event.key === "Enter" || event.key === " ") {
            switch (type) {
                case "save":
                    handleSubmit(onSubmit)();
                    break;
                case "remove":
                    handleRemovePet();
                    break;
                case "close":
                    handleClose();
                    break;
                default:
                    break;
            }
        }
    };

    const confirmRemovePet = async () => {
        const currentValues = getValues();
        const { policies } = currentValues;

        if (policies && policies.length > 0) {
            const petIdToRemove = activePet?.id;
            if (petIdToRemove) {
                const currentIndex = policies.findIndex(policy => policy.id === petIdToRemove);
                const updatedPolicies = policies.filter(policy => policy.id !== petIdToRemove);
                const extraData = currentValues.extra;
                const updatedIsMultiPetLinked = updatedPolicies?.length === 1 ? true : extraData?.isMultiPetLinked;

                // Determine the next active pet
                let nextActivePetId: string | undefined;
                const previousPolicy = policies[currentIndex - 1];
                if (currentIndex > 0 && !!previousPolicy) {
                    // If there's a previous pet, select it

                    nextActivePetId = previousPolicy.id;
                } else if (currentIndex === 0 && updatedPolicies.length > 0 && updatedPolicies[0]) {
                    // If it's the first pet and there are others, select the next one
                    nextActivePetId = updatedPolicies[0].id;
                }

                const updatedData = {
                    ...currentValues,
                    policies: updatedPolicies,
                    extra: { ...extraData, isMultiPetLinked: updatedIsMultiPetLinked }
                };

                const updatedQuote = await updateQuote?.mutateAsync({ quote: updatedData, updateType: "PET_REMOVED" });

                resetParentForm(updatedQuote);
                // Update the active pet
                if (nextActivePetId) {
                    handleActivePetChange(nextActivePetId);
                }
                onClose();
            }
        }
    };

    const cancelRemovePet = () => {
        setShowRemoveConfirmation(false);
    };

    const Footer = () => (
        <div className="mt-8 flex w-full flex-col gap-4">
            {isEditMode ? (
                <div className="flex w-full gap-4">
                    {totalPolicies.length > 1 && (
                        <Button variant="ghost" onClick={handleRemovePet} onKeyUp={e => handleKeyUp(e, "remove")} className="flex-1">
                            {Strings.REMOVE_PET}
                        </Button>
                    )}
                    <Button loading={isQuoteUpdating} variant="default" onClick={handleSubmit(onSubmit)} onKeyUp={e => handleKeyUp(e, "save")} className="flex-1">
                        {Strings.SAVE}
                    </Button>
                </div>
            ) : (
                <Button
                    loading={isQuoteUpdating}
                    variant="default"
                    onClick={handleSubmit(onSubmit)}
                    onKeyUp={e => handleKeyUp(e, "save")}
                    className="w-full"
                    startDecorator={<PlusCircle className="size-5" />}
                >
                    Add Pet
                </Button>
            )}
        </div>
    );

    return (
        <FormProvider {...form}>
            {showRemoveConfirmation ? (
                <div className="flex h-full flex-col items-center justify-center gap-8">
                    <h2 className="w-full text-balance text-center text-lg font-bold text-content-primary">
                        <span className="whitespace-normal">{Strings.REMOVE_PET_CONFIRMATION} </span>
                        <span className="whitespace-nowrap">
                            <span className="inline-block max-w-[200px] truncate align-bottom">{activePet?.name}</span>
                            <span>?</span>
                        </span>
                    </h2>

                    <div className="flex w-full gap-4">
                        <Button autoFocus={true} variant="ghost" onClick={cancelRemovePet} className="flex-1">
                            {Strings.CONFIRMATION_CANCEL}
                        </Button>
                        <Button variant="default" onClick={confirmRemovePet} className="flex-1" loading={isQuoteUpdating}>
                            Yes
                        </Button>
                    </div>
                </div>
            ) : (
                <>
                    {policies.length > 1 && (
                        <FormField className="mb-2">
                            <DynamicOptionsField
                                id="active-pet"
                                toggleLimit={3}
                                options={petOptions}
                                label=""
                                initialValue={activePet?.id}
                                onValueChange={value => {
                                    updateActivePet(value);
                                }}
                                constrainWidthToTrigger
                            />
                        </FormField>
                    )}

                    <div className="flex w-full flex-col" key={activePetIndex}>
                        <PetInfoEditor
                            petBreeds={breeds}
                            topBreeds={config.topBreeds}
                            policyIndex={activePetIndex}
                            allowDelete={false}
                            showAge={config.showAge ?? !config.showBirthDate}
                            showBirthDate={config.showBirthDate}
                            showMicrochip={config.showMicrochip}
                            ageField={config.ageField}
                            nameField={config.nameField}
                            breedField={config.breedField}
                            styles={config.formStyles}
                            breedListHeight="120px"
                        />
                        <Footer />
                    </div>
                </>
            )}
        </FormProvider>
    );
};

export function PetModal({ initialValues, triggerButton, isOpen, onClose, handleOpen, config, handleActivePetChange }: PetModalProps) {
    const isMultiPetLinked = useAppLayerContext().appState.isMultiPetLinked;
    const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false);
    const isEditMode = initialValues?.policies?.every(policy => policy.id !== undefined);

    const getTitle = () => {
        if (showRemoveConfirmation) {
            return null;
        }
        if (isEditMode && isMultiPetLinked && !!initialValues?.policies?.length && initialValues.policies.length > 1) {
            return Strings.EDIT_PETS;
        }

        return isEditMode ? Strings.EDIT_PET_DETAILS : <AddAPetTitle />;
    };

    const handleClose = () => {
        setShowRemoveConfirmation(false);
        onClose();
    };

    return (
        <div onKeyDown={e => e.stopPropagation()}>
            <ResponsiveModalSheet
                isOpen={isOpen}
                onClose={handleClose}
                title={getTitle()}
                sheetSide="top"
                triggerButton={React.cloneElement(triggerButton)}
                showCloseButton={!showRemoveConfirmation}
                styles={{
                    sheet: {
                        content: cn("flex flex-col", showRemoveConfirmation && "pb-16"),
                        title: "flex justify-between items-center"
                    },
                    modal: {
                        title: "flex justify-between items-center",
                        content: "flex flex-col !max-w-[360px]",
                        closeButton: "hidden"
                    }
                }}
            >
                {isOpen && !!config && (
                    <PetModalForm
                        initialValues={initialValues}
                        onClose={handleClose}
                        config={config}
                        isEditMode={isEditMode}
                        showRemoveConfirmation={showRemoveConfirmation}
                        setShowRemoveConfirmation={setShowRemoveConfirmation}
                        handleActivePetChange={handleActivePetChange}
                        isMultiPetLinked={isMultiPetLinked}
                    />
                )}
            </ResponsiveModalSheet>
        </div>
    );
}

export default PetModal;
