// components
import IconGrid from "@/shared/components/IconGrid";
import { Heading } from "@/shared/components/ui/Heading";
import { Button } from "@/shared/components/ui/Button";
import { IconButton } from "@/shared/components/ui/IconButton";
import { Badge } from "@/shared/components/ui/Badge";

// utils
import { PolicyUtils } from "@/shared/utils/PolicyUtils";
import { PreventiveCoverageType } from "@/shared/utils/CoverageUtils";

// media
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/pro-regular-svg-icons";
import DeleteIcon from "@/shared/media/icons/CircleMinusProRegular.svg";

// types
import { Card, CardHeader, CardContent, CardFooter } from "../ui/Card";
import { Pet } from "@/shared/types/Quote.interface";
import { IconGridItem } from "@/shared/types/IconGrid.interface";
import { cn } from "@/shared/utils";
import { useAppLayerContext } from "@/shared/contexts/AppLayer";

interface PreventiveCardProps {
    pet: Pet;
    price?: number;
    label: string;
    image: JSX.Element;
    benefits: IconGridItem[];
    currentWellnessLevel: string;
    wellnessLevel: PreventiveCoverageType;
    onChange: (coverageLevel: PreventiveCoverageType) => void;
    sellingPoints?: string[];
    totalBenefitsValue?: number;
    showRecommendedAge?: boolean;
    recommendPrevGoldCats?: boolean;
    columnClasses?: string;
    headerClasses?: string;
}
function PreventiveCard(props: PreventiveCardProps) {
    const {
        pet,
        price,
        label,
        benefits,
        image,
        wellnessLevel,
        sellingPoints,
        showRecommendedAge,
        totalBenefitsValue,
        currentWellnessLevel,
        onChange,
        columnClasses,
        headerClasses
    } = props;
    const isActive = wellnessLevel === currentWellnessLevel;
    const { recommendPrevGoldCats } = props;

    // Statsig - reduced-height
    const { hasReducedHeight } = useAppLayerContext().appState;

    return (
        <Card className="h-full rounded-3xl bg-background-primary shadow-md">
            <CardHeader
                className={cn(
                    headerClasses,
                    "flex flex-row items-center border-b border-stroke-secondary px-3",
                    hasReducedHeight ? "min-h-[150px] py-4 sm:min-h-[112px] sm:px-5 md:min-h-[150px] lg:min-h-[112px]" : "h-36 sm:px-4 lg:h-28"
                )}
            >
                <div className="flex flex-col text-content-primary">
                    <div
                        className={cn(
                            "flex flex-col lg:flex-row lg:items-center",
                            hasReducedHeight ? "gap-3 sm:flex-row sm:items-center sm:gap-2 md:flex-col md:items-start lg:gap-2" : "lg:gap-3"
                        )}
                    >
                        <Heading level="h3" className={cn("order-1 lg:order-none", hasReducedHeight ? "sm:order-none md:order-1" : " lg:-mt-2")}>
                            {label}
                        </Heading>
                        {recommendPrevGoldCats ? (
                            <Badge variant="secondary" className={cn(hasReducedHeight ? "" : "mb-3 mt-1 self-start", "w-fit")}>
                                Recommended for {pet.age < 3 ? "kittens" : "cats"}
                            </Badge>
                        ) : (
                            showRecommendedAge &&
                            Number.isInteger(pet.age) &&
                            pet.species && (
                                <Badge variant="secondary" className={cn(hasReducedHeight ? "" : "mb-3 mt-1 self-start", "w-fit")}>
                                    Recommended for {PolicyUtils.recommendedPreventiveText(pet.species, pet.age as number)}
                                </Badge>
                            )
                        )}
                    </div>
                    {totalBenefitsValue && <div className="text-content-primary">Up to ${totalBenefitsValue}/yr in benefits</div>}
                    {sellingPoints?.length && (
                        <div className={cn("mt-1 flex flex-col lg:flex-row lg:gap-2", hasReducedHeight ? "sm:flex-row sm:gap-2 md:flex-col md:gap-0" : "lg:-mt-1")}>
                            {sellingPoints.map((point, index) => (
                                <div key={index}>
                                    <FontAwesomeIcon icon={faCircleCheck} className="mr-1 text-content-brand-b" />
                                    {point}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                <div className="ml-auto">{image}</div>
            </CardHeader>
            <CardContent
                className={cn(
                    "flex flex-col items-center gap-2 text-content-primary sm:p-4",
                    hasReducedHeight ? "px-1 py-2 xs:px-2 sm:px-5 sm:py-4 md:px-3 md:py-2 lg:px-5 lg:py-4" : "px-3 py-4"
                )}
            >
                <IconGrid
                    gridItems={benefits}
                    wrapperClass={cn(
                        columnClasses,
                        "grid w-full",
                        hasReducedHeight ? "gap-y-2 sm:gap-y-1 gap-x-1 sm:gap-x-3 md:gap-x-1 md:gap-y-2 lg:gap-x-3 lg:gap-y-2" : "gap-1 grid-cols-1 xl:grid-cols-2 xl:gap-x-4"
                    )}
                    isPreventive
                />
            </CardContent>
            <CardFooter className="mt-auto flex items-center justify-between gap-2 border-t border-stroke-secondary px-3 py-5 sm:px-4">
                <span className="flex flex-row justify-between font-bold text-content-brand-b">${price}/mo</span>
                <div className="flex items-center gap-2">
                    <Button variant={`${isActive ? "secondary" : "outline-secondary"}`} onClick={() => !isActive && onChange(wellnessLevel)}>
                        {isActive ? `${label} Added` : `Add ${label}`}
                    </Button>
                    {isActive && (
                        <IconButton variant="destructive" className="p-0" title={`Remove ${label}`} onClick={() => onChange("None")}>
                            <DeleteIcon className="size-6" />
                        </IconButton>
                    )}
                </div>
            </CardFooter>
        </Card>
    );
}

export default PreventiveCard;
