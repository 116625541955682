// packages
import React, { useCallback, useEffect, useState, useRef, useMemo, ReactNode, createContext, useContext } from "react";
import { useDeepCompareMemo } from "@react-hookz/web";

// components
import ReimbursementTerms from "./ReimbursementTerms";
import { RadioButtonGroup } from "@/shared/components/ui/RadioButtonGroup";
import { FormField } from "@/shared/components/FormField";
import { CoverageIconGrid } from "@/shared/components/CoverageIconGrid";
import { PreventiveCards } from "@/shared/components/PreventiveCards";
import { Heading } from "@/shared/components/ui";
import AdditionalPlans from "@/shared/components/AdditionalPlans";
import DynamicOptionsField from "@/shared/components/DynamicOptionsField";
import { MultiPetSelect } from "./MultiPetSelect";
import MultipetToggle from "./MultipetToggle";
import TakeoverProvision from "./TakeoverProvision";
import { CustomizationSlot } from "./CustomizationSlot";

// utils
import { CoverageUtils, CoverageValue, TransactionFeeType } from "@/shared/utils/CoverageUtils";
import { PresetCoverageLevel, PreventiveConfig } from "@/shared/utils/CoverageUtils";
import { BuilderUtils } from "@/shared/utils/BuilderUtils";
import { AnalyticsUtils } from "../utils/AnalyticsUtils";
import { cn } from "../utils";
import ErrorUtils from "../utils/ErrorUtils";
import { PolicyUtils } from "../utils/PolicyUtils";

// hooks
import { useAppLayerContext } from "@/shared/contexts/AppLayer";
import { useFormParentContext } from "@/shared/contexts/FormParent";
import { useStatsig } from "../hooks/useStatsig";

// types
import { CoverageSettings, Option, Quote, Pet, InitialPetValues, SimpleOption } from "@/shared/types/Quote.interface";
import { FormStepProps } from "@/shared/types/Form";
import { PetUnderwriterType } from "spot-types/entities/PetQuote";
import { DialogProps } from "@/shared/types/Modals";
import { PetModalConfig } from "./PetModal";
import { PRIORITY_CODE_DEPRECATED } from "@/shared/types/SpotAPI";
import { Species } from "spot-types/entities/Species";

// config
import { PublicConfig } from "../PublicConfig";

// media

import { UsQuoteFormSteps } from "@/quote-ptz-us/formConfigTypes";
import { useIntersectionObserver } from "../hooks/useIntersectionObserver";
import PinnedCTA from "@/quote-ptz-us/src/components/PinnedCTA";

export type CoverageModalTerms = "reimbursement" | "annualLimit" | "annualDeductible";

type CustomCoverageProps = {
    editorConfig: {
        formId: string;
        title?: string;
        coveragePresetData?: PresetCoverageLevel[];
        termsInModal?: CoverageModalTerms[];
        samplePolicyUrl?: string;
        modalContent: React.ComponentType<DialogProps>;
        preventiveConfig: PreventiveConfig;
        includeTransactionFee?: boolean;
        feeType?: TransactionFeeType;
        exclusions?: ReactNode;
        customizationSlot?: ReactNode;
        petModalConfig: PetModalConfig;
        getModalInitialValues: (defaultCoverageSettings?: CoverageSettings, isMultiPetLinked?: boolean) => InitialPetValues;
        showTakeoverProvision?: boolean;
        priorityCode?: string;
        multiPetVariant: "card" | "hybrid" | "dropdown";
    };
};

type CoverageEditorProps = FormStepProps<Quote> & CustomCoverageProps;

export function CoverageEditor(props: CoverageEditorProps) {
    const {
        formId,
        title = "Create your plan",
        coveragePresetData,
        termsInModal = ["annualDeductible", "annualLimit", "reimbursement"],
        samplePolicyUrl = "",
        modalContent,
        preventiveConfig,
        includeTransactionFee = true,
        feeType = "per-quote",
        exclusions,
        customizationSlot,
        petModalConfig,
        showTakeoverProvision = false,
        priorityCode,
        multiPetVariant
    } = props?.editorConfig || {};

    // local state
    const [customCoverageSettings, setCustomCoverageSettings] = useState<CoverageValue | null>(null);

    const coverageLevelIndex = CoverageUtils.DEFAULT_COVERAGE_INDEX;

    const initialRef = useRef(false);

    // form state
    const { setValue, getValues, watch } = useFormParentContext<Quote>();

    const quote = watch();
    const policies = watch("policies");

    // hooks
    const { appState, updateAppState } = useAppLayerContext();
    const {
        isMultiPetLinked,
        updateQuote,
        isQuoteUpdating,
        isApplyAllHidden,
        underwriter,
        breakpoint,
        isFormLoaderVisible,
        petPlansObject,
        mockAPIError,
        activePetIndex = 0
    } = appState;

    const { petPlans, isPetPlansUpdating } = petPlansObject || {};

    const builderUtils = new BuilderUtils(underwriter as PetUnderwriterType);

    // Statsig - trust-pilot-vs-video-testimonials
    const { showVideoTestimonials } = useStatsig({
        layerId: PublicConfig.STATSIG_LAYER_ID_MAIN,
        layerParameters: [{ key: "showVideoTestimonials", defaultValue: false }] as const,
        queryParams: AnalyticsUtils.getQueryParams(quote?.extra?.queryParams)
    });

    // Statsig - coverageIconsCarousel
    const { coverageIconsCarousel } = useStatsig({
        layerId: PublicConfig.STATSIG_LAYER_ID_MAIN,
        layerParameters: [{ key: "coverageIconsCarousel", defaultValue: false }] as const,
        queryParams: AnalyticsUtils.getQueryParams(quote?.extra?.queryParams)
    });

    // todo: breakpoints refactor
    const showTwoColLayout = ["lg", "xl", "2xl"].includes(breakpoint ?? "");

    // Statsig - coverage-puppy-tips
    const { coverageOptionsPuppy } = useStatsig({
        layerId: PublicConfig.STATSIG_LAYER_DOG_EXPERIMENTS,
        layerParameters: [{ key: "coverageOptionsPuppy", defaultValue: { reimbursement: false, deductible: false, annualLimit: false } }] as const,
        queryParams: AnalyticsUtils.getQueryParams(quote?.extra?.queryParams)
    });

    // Statsig - Pinned Checkout CTA Step 2
    const { fixedCtaConfig } = useStatsig({
        layerId: PublicConfig.STATSIG_LAYER_ID_MAIN,
        layerParameters: [{ key: "fixedCtaConfig", defaultValue: { showPrice: false, scrollOnly: false, isEnabled: false } }] as const,
        queryParams: AnalyticsUtils.getQueryParams(quote?.extra?.queryParams)
    });

    // Statsig - isApplyToAllChecked
    const { isApplyToAllChecked } = useStatsig({
        layerId: PublicConfig.STATSIG_LAYER_ID_MAIN,
        layerParameters: [{ key: "isApplyToAllChecked", defaultValue: true }] as const,
        queryParams: AnalyticsUtils.getQueryParams(quote?.extra?.queryParams)
    });

    // Statsig - reduced-height
    const { hasReducedHeight } = useStatsig({
        layerId: PublicConfig.STATSIG_LAYER_ID_MAIN,
        layerParameters: [{ key: "hasReducedHeight", defaultValue: false }] as const,
        queryParams: AnalyticsUtils.getQueryParams(quote?.extra?.queryParams)
    });

    useEffect(() => {
        if (showVideoTestimonials) {
            updateAppState({ showVideoTestimonials });
        }
    }, [showVideoTestimonials, updateAppState]);
    // Statsig:end

    // Initial data
    const allPets = useDeepCompareMemo(() => policies ?? [], [policies]);
    const activePet = useDeepCompareMemo(() => policies?.[activePetIndex], [policies, activePetIndex]);
    const isAnnualBilling = appState.isAnnualBilling;
    const activePetAllPlans = useMemo(() => petPlans?.[activePet?.id ?? ``] ?? [], [activePet?.id, petPlans]);
    const policyPets: Option[] = useMemo(() => allPets.map(pet => ({ value: pet.id ?? ``, label: pet.name ?? `` })), [allPets]);
    const hasDogInMultipet = useMemo(
        () => allPets.length > 1 && allPets.some(pet => pet.species !== undefined && pet.species === Species.Dog) && isMultiPetLinked,
        [allPets, isMultiPetLinked]
    );

    const priceInfoData = useMemo(
        () => CoverageUtils.getPriceInfoData({ value: quote, includeTransactionFee, feeType, petIdToShow: !isMultiPetLinked ? activePet?.id : undefined }),
        [activePet?.id, feeType, includeTransactionFee, isMultiPetLinked, quote]
    );

    // format pet plan API data for UI components
    const allCoverageOptions = useMemo(
        () => (!!activePetAllPlans.length ? CoverageUtils.formatCustomCoverageData(activePetAllPlans, isAnnualBilling) : null),
        [activePetAllPlans, isAnnualBilling]
    );

    const { coverageTypes, annualLimits, annualDeductibles, reimbursementRates, preventiveCareOptions } = allCoverageOptions || {};

    // Updates coverage options with recommended tips for puppy coverage
    // Works for multipet (when pets are linked) OR active pet (when pets are not linked)
    const updateCoverageOptionsWithTips = (options: SimpleOption[], optionsKey: "reimbursement" | "annualLimit" | "deductible", activePet: Pet, hasDogInMultipet: boolean) => {
        if (!!options?.length && !!activePet?.species && activePet?.age !== undefined && activePet?.age !== null) {
            const { species, age } = activePet;
            return options.map(rate => ({
                value: rate.value,
                label: rate.label,
                tipText: PolicyUtils.getCoverageOptionTip(species, age, rate.value, optionsKey, hasDogInMultipet)
            }));
        }
    };

    // If statsig test is disabled, return the original options. Else, update with {puppy} tips
    const updatedReimbursementRates = useMemo(() => {
        if (!coverageOptionsPuppy.reimbursement) return reimbursementRates;
        return updateCoverageOptionsWithTips(reimbursementRates ?? [], "reimbursement", activePet as Pet, hasDogInMultipet as boolean);
    }, [reimbursementRates, activePet, coverageOptionsPuppy, hasDogInMultipet]);

    const updatedAnnualDeductibles = useMemo(() => {
        if (!coverageOptionsPuppy.deductible) return annualDeductibles;
        return updateCoverageOptionsWithTips(annualDeductibles ?? [], "deductible", activePet as Pet, hasDogInMultipet as boolean);
    }, [annualDeductibles, activePet, coverageOptionsPuppy, hasDogInMultipet]);

    const updatedAnnualLimits = useMemo(() => {
        if (!coverageOptionsPuppy.annualLimit) return annualLimits;
        return updateCoverageOptionsWithTips(annualLimits ?? [], "annualLimit", activePet as Pet, hasDogInMultipet as boolean);
    }, [annualLimits, activePet, coverageOptionsPuppy, hasDogInMultipet]);
    //

    // find options that have multiple values for matching multipet coverage settings
    const coverageValuesToMatch = useMemo(() => {
        if (!!allCoverageOptions) {
            const valuesWithMultipleOptions = CoverageUtils.findKeysWithMultipleOptions(allCoverageOptions);
            const matchCriteriaKeys = CoverageUtils.convertKeysToMatchCriteria(valuesWithMultipleOptions);
            return matchCriteriaKeys;
        }
        return [];
    }, [allCoverageOptions]);

    const transactionFee = includeTransactionFee ? (getValues("transactionFee.value") ?? 0) : 0;
    const coveragePresets = useMemo(() => {
        if (!!activePet?.id && !!petPlans && !!coveragePresetData) {
            const activePetPlans = petPlans[activePet.id];
            if (!!activePetPlans) {
                return CoverageUtils.getPresetPlansFromApiData({ petPlans: activePetPlans, presetCoverageLevels: coveragePresetData });
            }
        }
        return [];
    }, [activePet?.id, petPlans, coveragePresetData]);

    const allPetsHaveSameCoverages = useMemo(() => {
        if (!!allPets.length) {
            const allCoverageSettings = allPets.map(pet => pet?.coverageSettings);
            if (!!allCoverageSettings.length) {
                return CoverageUtils.allPetCoverageSettingsMatch(allCoverageSettings, coverageValuesToMatch);
            }
        }
        return false;
    }, [allPets, coverageValuesToMatch]);

    const allPetsHaveFetchedPlans = useMemo(() => {
        if (!!petPlans && !isPetPlansUpdating) {
            const allPlanIds = Object.keys(petPlans);
            return allPets.every(pet => !!pet.id && allPlanIds.includes(pet.id));
        }
        return false;
    }, [allPets, isPetPlansUpdating, petPlans]);

    const activePetCoverage = useDeepCompareMemo(() => {
        const coverage = !!activePet && customCoverageSettings ? customCoverageSettings : coveragePresets[coverageLevelIndex];
        return coverage;
    }, [customCoverageSettings, coveragePresets, coverageLevelIndex, activePet]);

    const getWellnessLevel = (coverageSettings?: CoverageSettings) => {
        if (!coverageSettings) return "None";
        const wellnessCoverage = coverageSettings?.coverages?.find(c => c.type?.includes(`wellness`))?.type;
        return wellnessCoverage === "wellness-gold" ? "wellness-gold" : wellnessCoverage === "wellness-platinum" ? "wellness-platinum" : "None";
    };

    const [coverageLevel, setCoverageLevel] = useState(
        activePet?.coverageSettings?.coverages?.some(coverage => coverage.type === "illness") ? "accidentAndIllness" : "accidentOnly"
    );

    const [annualLimitValue, setAnnualLimitValue] = useState(activePet?.coverageSettings?.amounts?.annualLimit?.toString());
    const [annualDeductibleValue, setAnnualDeductibleValue] = useState(activePet?.coverageSettings?.amounts?.annualDeductible?.toString());
    const [reimbursementValue, setReimbursementValue] = useState(activePet?.coverageSettings?.amounts?.reimbursementRate?.toString());
    const [wellnessValue, setWellnessValue] = useState(getWellnessLevel(activePet?.coverageSettings));

    const updatePricing = useCallback(
        (newCoverageSettings: CoverageSettings) => {
            if (!!petPlans && !!activePet?.id) {
                const calculatedData = CoverageUtils.calculatePriceData({
                    coverageSettings: newCoverageSettings,
                    policies: allPets,
                    isMultiPetLinked,
                    activePetId: activePet.id,
                    petPlans: petPlans,
                    coverageValuesToMatch,
                    isAnnualBilling,
                    transactionFee,
                    feeType
                });

                // Restructure the data to fit our new app state structure
                const newCalculatedPriceData = {
                    preDiscountPrice: calculatedData.preDiscountPrice,
                    discountAmount: calculatedData.discountAmount,
                    finalPrice: calculatedData.finalPrice,
                    singlePetPriceData: calculatedData.perPetPriceData
                };

                // Update the app state with the new calculated price data

                updateAppState({
                    calculatedPriceData: newCalculatedPriceData
                });
            }
        },
        [activePet?.id, allPets, coverageValuesToMatch, feeType, isAnnualBilling, isMultiPetLinked, petPlans, transactionFee, updateAppState]
    );

    const updateCoverageSettings = useCallback(
        async (newCoverageSettings: CoverageSettings | null, isApplyToAllPetsChecked: boolean) => {
            if (!newCoverageSettings) return;
            setCustomCoverageSettings({ name: `custom`, config: newCoverageSettings });

            updatePricing(newCoverageSettings);

            const { policies } = getValues();
            if (!!policies?.length) {
                if (isApplyToAllPetsChecked) {
                    policies.forEach((policy, index) => {
                        setValue(`policies.${index}.coverageSettings`, newCoverageSettings);
                    });
                } else {
                    setValue(`policies.${activePetIndex}.coverageSettings`, newCoverageSettings);
                }
            }

            const allValues = getValues();
            try {
                await updateQuote?.mutateAsync({ quote: allValues });
            } catch (error) {
                const hasPcodeError = ErrorUtils.hasSpotError(error, PRIORITY_CODE_DEPRECATED);

                if (hasPcodeError) {
                    let retryData = { ...allValues, affiliateCode: "SPOT", discountCode: "SPOT" };

                    if (PublicConfig.ENVIRONMENT === "development" && mockAPIError) {
                        const updatedExtra = { ...retryData.extra, sim: "" };
                        retryData = { ...retryData, extra: updatedExtra };
                    }

                    await updateQuote?.mutateAsync({ quote: retryData });
                }
            }
        },
        [activePetIndex, getValues, setCustomCoverageSettings, setValue, updatePricing, updateQuote, mockAPIError]
    );

    const handleCustomAmounts = (change: { setting: "annualLimit" | "reimbursementRate" | "annualDeductible"; value: string }) => {
        if (!activePetCoverage || !activePetAllPlans) {
            console.error("Active pet or plans data is undefined.");
            return;
        }

        const updatedAmounts = {
            ...activePetCoverage.config?.amounts,
            [change.setting]: Number(change.value)
        };

        const updatedCoverageSettings: CoverageSettings = {
            ...activePetCoverage.config,
            amounts: updatedAmounts
        };

        const matchingPlan = CoverageUtils.getPlanDataFromCoverageSettings(activePetAllPlans, updatedCoverageSettings, coverageValuesToMatch);

        if (matchingPlan) {
            const newCoverageSettings = CoverageUtils.getCoverageSettingsFromPlanData(
                matchingPlan,
                wellnessValue === "None" ? undefined : (wellnessValue as "wellness-gold" | "wellness-platinum")
            );
            updateCoverageSettings(newCoverageSettings, !!isMultiPetLinked);
        } else {
            console.error("No matching plan found for the new coverage settings.");
        }
    };

    const handleCoverageLevelChange = (planLevel: "accidentOnly" | "accidentAndIllness") => {
        let newCoverages = [...(activePet?.coverageSettings?.coverages || [])];

        // Adjust coverage based on the coverageLevel change
        const hasIllnessCoverage = newCoverages.some(c => c.type === "illness");
        if (planLevel === "accidentAndIllness" && !hasIllnessCoverage) {
            // Push "illness" coverage to newCoverages with a temporary filler ID
            newCoverages.push({ type: "illness", id: "tempId", name: "Illness Coverage" });
        } else if (planLevel === "accidentOnly" && hasIllnessCoverage) {
            // Remove "illness" coverage if switching back to accidentOnly
            newCoverages = newCoverages.filter(c => c.type !== "illness");
        }

        const updatedCoverageSettings: CoverageSettings = {
            ...activePet?.coverageSettings,
            coverages: newCoverages
        };

        const matchingPlan = CoverageUtils.getPlanDataFromCoverageSettings(activePetAllPlans, updatedCoverageSettings, coverageValuesToMatch);

        if (matchingPlan) {
            const newCoverageSettings = CoverageUtils.getCoverageSettingsFromPlanData(
                matchingPlan,
                wellnessValue === "None" ? undefined : (wellnessValue as "wellness-gold" | "wellness-platinum")
            );
            updateCoverageSettings(newCoverageSettings, !!isMultiPetLinked);
        } else {
            console.error("No matching plan found for the new coverage settings.");
        }
    };

    const handlePreventiveChange = (wellnessType: string) => {
        // When wellnessType is "None", filter out all wellness coverages, otherwise construct new wellness coverage settings
        const isRemovingWellness = wellnessType === "None";

        // Clone existing coverages, excluding any existing wellness coverage if "None" or updating to the new selection
        const newCoverages = (activePet?.coverageSettings?.coverages || []).filter(coverage => !coverage.type?.includes("wellness"));

        if (!isRemovingWellness && (wellnessType === "wellness-gold" || wellnessType === "wellness-platinum")) {
            const newCoverageSettings: CoverageSettings = {
                ...activePet?.coverageSettings,
                coverages: [{ type: wellnessType }]
            };

            const matchingPlan = CoverageUtils.getPlanDataFromCoverageSettings(activePetAllPlans, newCoverageSettings, coverageValuesToMatch);
            if (matchingPlan) {
                const wellnessRider = CoverageUtils.getRiderFromCoverageType(wellnessType, matchingPlan.riders);
                if (wellnessRider && wellnessRider.id) {
                    // Add the matched wellness coverage with dynamically found id
                    newCoverages.push({ type: wellnessType, id: wellnessRider.id, preventivePrice: wellnessRider.basePrice?.value });
                } else {
                    console.error("No matching wellness rider found for selected plan.");
                }
            } else {
                console.error("No matching plan found for updated wellness coverage settings.");
            }
        }

        // Proceed to update regardless of whether a new wellness plan was added or all wellness coverages were removed
        const updatedCoverageSettings = { ...activePetCoverage?.config, coverages: newCoverages };
        updateCoverageSettings(updatedCoverageSettings, !!isMultiPetLinked);
    };

    const handleActivePetChange = (value: string) => {
        const currentPolicies = getValues().policies ?? [];
        const newActivePetIndex = currentPolicies.findIndex(pet => pet.id === value);

        if (newActivePetIndex === -1) {
            console.warn("Pet not found in current policies");
            return;
        }

        updateAppState({ activePetIndex: newActivePetIndex });
        const selectedPet = currentPolicies[newActivePetIndex];
        if (selectedPet && selectedPet.coverageSettings) {
            const { coverageSettings } = selectedPet;
            if (coverageSettings.amounts?.annualLimit) {
                setAnnualLimitValue(coverageSettings.amounts.annualLimit.toString());
            }
            if (coverageSettings.amounts?.annualDeductible) {
                setAnnualDeductibleValue(coverageSettings.amounts.annualDeductible.toString());
            }
            if (coverageSettings.amounts?.reimbursementRate) {
                setReimbursementValue(coverageSettings.amounts.reimbursementRate.toString());
            }
            if (coverageSettings.coverages) {
                setCoverageLevel(coverageSettings.coverages.some(coverage => coverage.type === "illness") ? "accidentAndIllness" : "accidentOnly");
                setWellnessValue(getWellnessLevel(coverageSettings));
            }
            setCustomCoverageSettings({ name: "custom", config: coverageSettings });
        }
    };

    useEffect(() => {
        // Initialization
        if (!isQuoteUpdating && !isPetPlansUpdating && allPetsHaveFetchedPlans && !initialRef.current && !!activePet?.coverageSettings) {
            const allPetPlansHaveSameOptions = CoverageUtils.allPetPlansHaveSameOptions(petPlans);

            // If all pets do not have the same coverage options available, hide the "Apply to all pets" feature
            updateAppState({ isApplyAllHidden: !allPetPlansHaveSameOptions });
            initialRef.current = true;
        }
    }, [activePet?.coverageSettings, allPetsHaveFetchedPlans, allPetsHaveSameCoverages, isPetPlansUpdating, isQuoteUpdating, petPlans, updateAppState]);

    useEffect(() => {
        if (!customCoverageSettings && !!activePet?.coverageSettings) {
            setCustomCoverageSettings({ name: "custom", config: activePet.coverageSettings });
        }
    }, [activePet?.coverageSettings, customCoverageSettings]);

    useEffect(() => {
        if (!!policies && policies.length === 1 && !isPetPlansUpdating && !isQuoteUpdating) {
            updateAppState({ isApplyAllHidden: true });
        }
        if (petPlans !== undefined && !!policies && policies?.length > 1 && !isPetPlansUpdating && !isQuoteUpdating) {
            const allPetPlansHaveSameOptions = CoverageUtils.allPetPlansHaveSameOptions(petPlans);
            if (!allPetPlansHaveSameOptions) {
                updateAppState({ isApplyAllHidden: true, isMultiPetLinked: false });
            } else {
                updateAppState({ isApplyAllHidden: false, isMultiPetLinked });
            }
        }
    }, [isPetPlansUpdating, isQuoteUpdating, petPlans, policies, updateAppState, isMultiPetLinked]);

    const shouldShowLoader = !coveragePresets.length || !activePet || !underwriter || isPetPlansUpdating;

    useEffect(() => {
        if (shouldShowLoader) {
            updateAppState({ isFormLoaderVisible: true });
        } else {
            updateAppState({ isFormLoaderVisible: false });
        }
    }, [activePet, coveragePresets.length, isFormLoaderVisible, isPetPlansUpdating, shouldShowLoader, underwriter, updateAppState]);

    // Statsig - iconHeaderVariant
    // "centered" vs "row"
    const { iconHeaderVariant } = useStatsig({
        layerId: PublicConfig.STATSIG_LAYER_ID_MAIN,
        layerParameters: [{ key: "iconHeaderVariant", defaultValue: "default" }] as const,
        queryParams: AnalyticsUtils.getQueryParams(quote?.extra?.queryParams)
    });
    // Statsig end

    // todo: winner, needs to be made default
    const isIconHeaderVariantRow = iconHeaderVariant === "row";

    const iconGridRenderWrapperClass = {
        "lg:px-0 xl:px-0 xl:py-6": coverageIconsCarousel
    };

    const renderCoverageTypes = (props: { showLabel?: boolean } = {}) => {
        if (!coverageTypes || coverageTypes.length < 1) return null;
        const { showLabel } = props;
        return (
            <div className="flex flex-col gap-5">
                <FormField>
                    {showLabel && <span className="mb-2 text-sm font-semibold text-content-primary">Coverage</span>}
                    <RadioButtonGroup
                        initialValue={coverageLevel}
                        options={coverageTypes}
                        onValueChange={value => {
                            setCoverageLevel(value);
                            handleCoverageLevelChange(value as "accidentOnly" | "accidentAndIllness");
                        }}
                        className="whitespace-nowrap"
                    />
                </FormField>
            </div>
        );
    };

    const renderIconGrid = () => {
        if (!activePetCoverage || !activePet || !underwriter) return null;

        const cardWrapperClass = {
            "border-none p-0": showTwoColLayout,
            "px-0": coverageIconsCarousel // allow for edge to edge scrolling
        };

        const iconGridContentWrapperClass = {
            "grid gap-3 max-lg:px-4 grid-cols-3 md:grid-cols-4 xl:grid-cols-5 lg:gap-x-2 lg:gap-y-4": !coverageIconsCarousel && !hasReducedHeight,
            "flex items-start": coverageIconsCarousel,
            "grid gap-3 max-lg:px-4 grid-cols-3 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-4 xl:grid-cols-5 lg:gap-x-2 lg:gap-y-4": hasReducedHeight
        };

        const iconGridItemClass = {
            "flex-col text-center": showTwoColLayout,
            "flex-col text-center md:w-[160px] xl:w-[150px] mx-auto": !showTwoColLayout && !coverageIconsCarousel,
            "flex-col": coverageIconsCarousel
        };

        const titleWrapperClass = {
            "lg:px-0": !coverageIconsCarousel
        };

        return (
            <CustomizationSlot
                type="icon-grid"
                data={quote}
                formId={formId}
                formStepId={UsQuoteFormSteps.COVERAGE.id}
                fallback={
                    <CoverageIconGrid
                        title={showTwoColLayout ? "Helps cover eligible claims for:" : ""}
                        titleWrapperClass={cn(titleWrapperClass)}
                        titleModal={showTwoColLayout}
                        coverageInfo={activePetCoverage.config}
                        species={activePet?.species}
                        underwriter={underwriter}
                        samplePolicyUrl={samplePolicyUrl}
                        modalContent={modalContent}
                        wrapperClass={cn(cardWrapperClass)}
                        iconGridProps={{
                            overrideScreenCheck: showTwoColLayout,
                            wrapperClass: cn(iconGridContentWrapperClass),
                            itemClass: cn(iconGridItemClass),
                            enableCarousel: coverageIconsCarousel
                        }}
                        iconsPerBreakpoint={{ SM: hasReducedHeight ? 12 : 9, MD: hasReducedHeight ? 15 : 12 }}
                        iconHeaderVariant={iconHeaderVariant}
                        renderCoverageFields={renderCoverageFields}
                        renderCoverageTypes={renderCoverageTypes}
                        renderReimbursementTerms={() => <ReimbursementTerms termsInModal={termsInModal} />}
                    />
                }
                priorityCode={priorityCode}
            />
        );
    };

    const renderCoverageFields = () => {
        const wrapperClass = showTwoColLayout ? "flex flex-col" : "grid lg:grid-cols-3";
        return (
            <div className={cn("gap-3", wrapperClass)}>
                {!!updatedAnnualLimits && updatedAnnualLimits.length > 0 && (
                    <FormField>
                        <DynamicOptionsField
                            id="coverage-annual-limit"
                            options={updatedAnnualLimits}
                            label="Annual Limit"
                            initialValue={annualLimitValue}
                            constrainWidthToTrigger={true}
                            onValueChange={value => {
                                setAnnualLimitValue(value);
                                handleCustomAmounts({ setting: "annualLimit", value });
                            }}
                        />
                    </FormField>
                )}
                {!!updatedReimbursementRates && updatedReimbursementRates.length > 0 && (
                    <FormField>
                        <DynamicOptionsField
                            id="coverage-reimbursement-rate"
                            options={updatedReimbursementRates}
                            label="Reimbursement Rate"
                            initialValue={reimbursementValue}
                            onValueChange={value => {
                                setReimbursementValue(value);
                                handleCustomAmounts({ setting: "reimbursementRate", value });
                            }}
                        />
                    </FormField>
                )}
                {!!updatedAnnualDeductibles && updatedAnnualDeductibles.length > 0 && (
                    <FormField>
                        <DynamicOptionsField
                            id="coverage-annual-deductible"
                            label="Annual Deductible"
                            options={updatedAnnualDeductibles}
                            initialValue={annualDeductibleValue}
                            onValueChange={value => {
                                setAnnualDeductibleValue(value);
                                handleCustomAmounts({ setting: "annualDeductible", value });
                            }}
                        />
                    </FormField>
                )}
            </div>
        );
    };

    const { ref: exclusionsRef, isIntersecting: isExclusionsInView } = useIntersectionObserver();

    return (
        <CoverageEditorProvider editorConfig={props.editorConfig}>
            {!shouldShowLoader && (
                <div className={cn("flex flex-col", hasReducedHeight ? "gap-4" : "gap-7")}>
                    <div className="flex items-center justify-between">
                        <Heading level="h1" className="text-lg font-bold">
                            {title}
                        </Heading>
                        {isApplyAllHidden ? null : (
                            <MultipetToggle
                                quoteId={quote?.id}
                                policies={props?.value?.policies}
                                allPetsHaveSameCoverages={allPetsHaveSameCoverages}
                                activePet={activePet}
                                updateCoverageSettings={updateCoverageSettings}
                            />
                        )}
                    </div>

                    <MultiPetSelect
                        variant={multiPetVariant}
                        policies={policies ?? []}
                        activePet={activePet}
                        setActivePetIndex={value => updateAppState({ activePetIndex: value })}
                        policyPets={policyPets}
                        handleActivePetChange={handleActivePetChange}
                        isMultiPetLinked={isMultiPetLinked}
                        isApplyAllHidden={isApplyAllHidden}
                        isQuoteUpdating={isQuoteUpdating}
                        priceInfoData={priceInfoData}
                        updateCoverageSettings={updateCoverageSettings}
                        policiesCount={quote?.policies?.length ?? 0}
                        petModalConfig={petModalConfig}
                        allPetsHaveSameCoverages={allPetsHaveSameCoverages}
                    />
                    <div className={cn("flex flex-col", hasReducedHeight ? "gap-7" : "")}>
                        {/* 
                            START COMMENT
                            "Hey, what's up with this weird React.Fragment?"
                            This is to make sure that the UI elements that take an initial value (like the radio groups) properly display the current plan values
                            when changing the active pet, since those initial values only get set on first render. 
                        */}
                        <React.Fragment key={activePet?.id}>
                            {/** END COMMENT */}
                            {showTwoColLayout ? (
                                <div className="mt-3 grid rounded-3xl border border-stroke-primary shadow-md lg:grid-cols-3">
                                    <div className="flex flex-col gap-3 border-stroke-primary p-4 max-lg:border-b sm:px-6 md:pt-6 lg:border-r lg:px-4 lg:pt-4 xl:gap-4 xl:p-6">
                                        {renderCoverageTypes({ showLabel: true })}
                                        {renderCoverageFields()}
                                        <ReimbursementTerms termsInModal={termsInModal} />
                                    </div>
                                    <div className={cn("px-4 pb-4 pt-6 lg:col-span-2 lg:p-4 xl:p-6 xl:pb-4", iconGridRenderWrapperClass)}>{renderIconGrid()}</div>
                                </div>
                            ) : (
                                <>
                                    <div className="mt-3 flex flex-col gap-4">
                                        {!isIconHeaderVariantRow && renderCoverageTypes()}
                                        {renderIconGrid()}
                                    </div>
                                    {!isIconHeaderVariantRow && (
                                        <div className="mt-7 flex flex-col gap-4">
                                            {renderCoverageFields()}
                                            {<ReimbursementTerms termsInModal={termsInModal} />}
                                        </div>
                                    )}
                                </>
                            )}
                        </React.Fragment>

                        {showTakeoverProvision && <TakeoverProvision />}

                        <CustomizationSlot
                            type="above-preventive"
                            data={quote}
                            formId={formId}
                            formStepId={UsQuoteFormSteps.COVERAGE.id}
                            formData={props.value?.extra?.formData}
                            updateData={data => builderUtils.updateQuoteExtraData({ quote: props.value, newDataArray: data, updateQuote: () => updateQuote })}
                            priorityCode={priorityCode}
                        />

                        {!!activePetCoverage && underwriter && !!preventiveCareOptions && preventiveCareOptions.length > 1 && (
                            <div className={cn("flex flex-col gap-4 ", hasReducedHeight ? "" : "mt-7 lg:mt-10")}>
                                <div className="flex flex-col gap-2 text-center">
                                    <Heading level="h2" className="text-lg font-bold">
                                        Add {hasReducedHeight ? "" : `${preventiveConfig.labels.basic} or ${preventiveConfig.labels.advanced} `}Preventive Care Coverage
                                    </Heading>
                                    <p className="text-sm text-content-secondary">
                                        Get fixed reimbursement amounts every year for certain vaccines, wellness exams, and more, with these optional wellness packages.
                                    </p>
                                </div>
                                <PreventiveCards
                                    pet={activePet as Pet}
                                    preventiveOptions={preventiveCareOptions}
                                    preventiveConfig={preventiveConfig}
                                    underwriter={underwriter}
                                    wellnessLevel={wellnessValue}
                                    handleChange={value => {
                                        setWellnessValue(value);
                                        handlePreventiveChange(value);
                                    }}
                                />
                                {!!coverageTypes && coverageTypes.length === 1 && <AdditionalPlans underwriter={underwriter} />}
                                {!!exclusions && (
                                    <div ref={exclusionsRef} className="flex flex-col">
                                        {exclusions}
                                    </div>
                                )}
                                {!!customizationSlot && <>{customizationSlot}</>}
                                {fixedCtaConfig.isEnabled && (
                                    <PinnedCTA
                                        quote={quote}
                                        exclusionsInView={isExclusionsInView}
                                        priceVariant={fixedCtaConfig.showPrice}
                                        scrollVariant={fixedCtaConfig.scrollOnly}
                                    />
                                )}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </CoverageEditorProvider>
    );
}

const CoverageEditorContext = createContext<CustomCoverageProps["editorConfig"] | undefined>(undefined);

export const useCoverageEditorContext = () => {
    const context = useContext(CoverageEditorContext);
    if (context === undefined) {
        throw new Error("useCoverageEditorContext must be used within a CoverageEditorProvider");
    }
    return context;
};

interface CoverageEditorProviderProps {
    children: ReactNode;
    editorConfig: CustomCoverageProps["editorConfig"];
}

const CoverageEditorProvider: React.FC<CoverageEditorProviderProps> = ({ children, editorConfig }) => {
    return <CoverageEditorContext.Provider value={editorConfig}>{children}</CoverageEditorContext.Provider>;
};
