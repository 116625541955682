import React, { useRef, useState } from "react";
import { cn } from "../utils";
import { IconGridProps, IconGridItem } from "../types/IconGrid.interface";
import { ChevronDown, ChevronUp } from "lucide-react";
import { Popover, PopoverTrigger } from "@radix-ui/react-popover";
import { useStatsig } from "@/shared/hooks/useStatsig";
import { PublicConfig } from "@/shared/PublicConfig";
import { Carousel, CarouselContent, CarouselItem, CarouselNavDots, CarouselNext, CarouselPrevious } from "@/shared/components/ui/carousel";
import { useAppLayerContext } from "../contexts/AppLayer";

const chunkArray = (array: IconGridItem[], chunkSize: number) => {
    return array.reduce((resultArray: IconGridItem[][], item, index) => {
        const chunkIndex = Math.floor(index / chunkSize);
        if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = []; // Start a new chunk
        }
        resultArray[chunkIndex].push(item);
        return resultArray;
    }, []);
};

export default function IconGrid({
    gridItems,
    wrapperClass,
    isPreventive = false,
    enableCarousel = false,
    initialViewableCount = 4,
    overrideScreenCheck = false,
    isLargeScreen,
    iconHeaderVariant
}: IconGridProps) {
    const { breakpoint } = useAppLayerContext().appState;
    const gridRef = useRef<HTMLDivElement>(null);
    const isBrowser = typeof window !== `undefined`;
    const initialScrollOffset = isBrowser ? window.scrollY : 0;
    const [showMore, setShowMore] = useState(false);
    const [scrollOffset, setScrollOffset] = useState(initialScrollOffset);
    const displayedItems = showMore || isPreventive ? gridItems : gridItems.slice(0, initialViewableCount);

    // Determines the number of icons to display per slide in the carousel
    // Adjustments may need to be made to the grid carousel group classes as well
    const iconsPerSlide = {
        base: 6,
        "2xs": 6,
        xs: 6,
        sm: 8,
        md: 10,
        lg: 12,
        xl: 12,
        "2xl": 12
    };
    const carouselGroupClasses = "grid grid-cols-2 gap-x-1 gap-y-2 px-6 2xs:px-9 xs:grid-cols-3 sm:grid-cols-4 sm:px-6 md:grid-cols-5 md:px-11 lg:grid-cols-4 lg:gap-y-4";

    const carouselGroups: IconGridItem[][] = enableCarousel ? chunkArray(gridItems, iconsPerSlide[breakpoint ?? "sm"]) : [];

    const handleShowMore = () => {
        if (isBrowser) {
            setScrollOffset(window.scrollY);
        }
        setShowMore(true);
    };

    const handleShowLess = () => {
        setShowMore(false);
        if (isBrowser) {
            window.scrollTo({ top: scrollOffset, left: 0, behavior: "auto" });
        }
    };

    // Statsig:start
    const { showCoverageIconContent } = useStatsig({
        layerId: PublicConfig.STATSIG_LAYER_ID_MAIN,
        layerParameters: [{ key: "showCoverageIconContent", defaultValue: false }] as const
    });
    // Statsig:end

    const conditionalTriggerClasses = {
        "flex-row": isPreventive, // Preventive coverage Cards
        "flex-col": !isPreventive, // Accident + Illness and Accident Only icon grid
        "hover:bg-background-secondary py-2": showCoverageIconContent && !isPreventive,
        "pointer-events-none": !showCoverageIconContent || isPreventive
    };

    const hasMoreIconsThanInitialCount = !!initialViewableCount && gridItems.length > initialViewableCount;
    // If overrideScreenCheck is true, show the "Show More" button regardless of screen size
    const showMoreIconsButton = !enableCarousel && hasMoreIconsThanInitialCount && !isPreventive && (overrideScreenCheck || !isLargeScreen);

    const renderIconItem = (item: IconGridItem, index: number) => {
        const { icon, text, amount } = item;

        const iconTextClass = {
            "text-left": isPreventive,
            "text-center md:mx-3 lg:mx-0 line-clamp-3 md:line-clamp-2 text-balance": !isPreventive,
            "select-none": enableCarousel
        };

        return (
            <div key={`icon-item-${index + 1}`} className={cn("flex w-full items-center justify-between gap-1 text-xs text-content-secondary sm:gap-2 md:gap-1 lg:gap-2")}>
                <div className="flex w-full justify-between">
                    <Popover>
                        <PopoverTrigger
                            className={cn(`flex w-full items-center gap-1 rounded text-center`, conditionalTriggerClasses)}
                            tabIndex={isPreventive || !showCoverageIconContent ? -1 : undefined}
                        >
                            <div className="justify-center">{icon}</div>
                            {!!text && <span className={cn("font-medium leading-5", iconTextClass)}>{text}</span>}
                        </PopoverTrigger>
                    </Popover>
                </div>
                {!!amount && <span>${amount}</span>}
            </div>
        );
    };

    return (
        <>
            {!!gridItems.length && (
                <div ref={gridRef} className={cn("w-full")}>
                    <Carousel disabled={!enableCarousel} options={{ startIndex: 0, align: "start" }}>
                        <CarouselContent className={cn(wrapperClass)}>
                            {!enableCarousel && displayedItems.map(({ icon, text, amount }, index) => renderIconItem({ icon, text, amount }, index))}
                            {enableCarousel &&
                                !!carouselGroups.length &&
                                carouselGroups.map((groups, index) => (
                                    <CarouselItem key={`carousel-group-${index + 1}`} className={cn(carouselGroupClasses)}>
                                        {groups.map((item, index) => renderIconItem(item, index))}
                                    </CarouselItem>
                                ))}
                        </CarouselContent>
                        {enableCarousel && carouselGroups?.length > 1 && (
                            <>
                                {/* todo: shad typescript issue. children should not be required for these components */}
                                <CarouselPrevious className="left-[4px] border-none bg-background-secondary p-0 focus:absolute focus:rounded-full sm:left-2 md:left-4">
                                    <></>
                                </CarouselPrevious>
                                <CarouselNext className="right-[4px] border-none bg-background-secondary p-0 focus:absolute focus:rounded-full sm:right-2 md:right-4">
                                    <></>
                                </CarouselNext>
                                <CarouselNavDots className="mt-4" />
                            </>
                        )}
                    </Carousel>
                </div>
            )}

            {showMoreIconsButton && (
                <div className="col-span-2 flex items-center justify-center">
                    <button type="button" className="mt-4 flex items-center justify-center" onClick={!showMore ? handleShowMore : handleShowLess}>
                        <div className="text-md text-type-primary mr-2">{!showMore ? `Show More` : `Show Less`}</div>
                        {!showMore ? <ChevronDown /> : <ChevronUp />}
                    </button>
                </div>
            )}
        </>
    );
}
