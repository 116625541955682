import React, { PropsWithChildren } from "react";
import { useAppLayerContext } from "../contexts/AppLayer";
import { ModalWrapper, ModalWrapperStyles } from "./ui/ModalWrapper";
import { SheetWrapper, SheetWrapperStyles } from "./ui/SheetWrapper";
import { ButtonProps, DialogClose } from "./ui";
import { X } from "lucide-react";
import { Breakpoint } from "../hooks/useBreakpoint";

interface ResponsiveModalSheetProps {
    triggerButton: React.ReactElement<ButtonProps>;
    isOpen: boolean;
    onClose: () => void;
    sheetSide?: "top" | "bottom" | "left" | "right";
    title?: string | React.ReactNode;
    description?: string;
    styles?: StylesProp;
    showCloseButton?: boolean;
}

interface StylesProp {
    sheet?: SheetWrapperStyles;
    modal?: ModalWrapperStyles;
}

export const ResponsiveModalSheet: React.FC<PropsWithChildren<ResponsiveModalSheetProps>> = ({
    triggerButton,
    isOpen,
    onClose,
    sheetSide = "bottom",
    children,
    title,
    description,
    showCloseButton = true,
    styles = {}
}) => {
    const {
        appState: { breakpoint }
    } = useAppLayerContext();

    const sheetBreakpoints: Breakpoint[] = ["base", "2xs"];

    if (!!breakpoint && sheetBreakpoints.includes(breakpoint)) {
        return (
            <>
                {triggerButton}
                <SheetWrapper isOpen={isOpen} onClose={onClose} title={title} description={description} side={sheetSide} showCloseButton={showCloseButton} styles={styles.sheet}>
                    {children}
                </SheetWrapper>
            </>
        );
    }

    return (
        <>
            {triggerButton}
            <ModalWrapper
                isOpen={isOpen}
                onClose={onClose}
                title={title}
                description={description}
                styles={styles.modal}
                footer={
                    showCloseButton ? (
                        <DialogClose position="bottom-center" className="flex gap-2 px-4">
                            <X className="size-4" />
                            <span className="">Close</span>
                        </DialogClose>
                    ) : null
                }
            >
                {children}
            </ModalWrapper>
        </>
    );
};

ResponsiveModalSheet.displayName = "ResponsiveModalSheet";
