import { useState, useEffect, useCallback } from "react";
import resolveConfig from "tailwindcss/resolveConfig";
import type { Config } from "tailwindcss";

// Import the config using an import statement with type assertion
import * as tailwindConfigModule from "../../tailwind.config.js";
const tailwindConfig = (tailwindConfigModule as unknown as { default: Config }).default;

// Resolve the config
const fullConfig = resolveConfig(tailwindConfig);
const breakpoints = { base: "0px", "2xs": "360px", xs: "428px", ...fullConfig.theme?.screens } || {};

export type Breakpoint = "base" | keyof typeof breakpoints;

function debounce<T extends (...args: any[]) => any>(func: T, wait: number): (...args: Parameters<T>) => void {
    let timeout: ReturnType<typeof setTimeout> | null = null;
    return (...args: Parameters<T>) => {
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => func(...args), wait);
    };
}

export function useBreakpoint(): Breakpoint {
    const [breakpoint, setBreakpoint] = useState<Breakpoint>("base");

    const handleResize = useCallback(() => {
        const width = window.innerWidth;
        let newBreakpoint: Breakpoint = "base";

        for (const [key, value] of Object.entries(breakpoints)) {
            const breakpointValue = parseInt(String(value).replace("px", ""), 10);
            if (width >= breakpointValue) {
                newBreakpoint = key as Breakpoint;
            } else {
                break;
            }
        }

        setBreakpoint(newBreakpoint);
    }, []);

    useEffect(() => {
        const debouncedHandleResize = debounce(handleResize, 100);

        // Initial call
        handleResize();

        // Add event listener
        window.addEventListener("resize", debouncedHandleResize);

        // Cleanup
        return () => window.removeEventListener("resize", debouncedHandleResize);
    }, [handleResize]);

    return breakpoint;
}
